export const posts = [
    {
        id: '0',
        title: 'Top-Quality Towing Services in Salem, OR',
        excerpt: 'Discover why Salem Towing Service is your go-to option for towing in Salem, OR. Whether you\'re dealing with a breakdown or need emergency assistance, our skilled team is here to help. We\'re dedicated to providing prompt, efficient service and ensuring your vehicle is handled with care. Need a tow in Salem? Call us, and we\'ll be right there.',
        slug: 'top-quality-towing-services-in-salem',
        tags: ['Towing', 'Emergency Assistance', 'Salem'],
        metaDescription: 'Looking for reliable towing services in Salem, OR? Salem Towing Service offers professional, efficient towing solutions for all your needs. Learn more about our services.',
        lastModified: new Date().toISOString()
    },
    {
        id: '1',
        title: 'Why Choose Salem Towing Service for Your Needs',
        excerpt: 'When it comes to finding the best towing service in Salem, OR, what sets a company apart? In this article, we explore what makes Salem Towing Service stand out. Learn about our commitment to reliability, professionalism, and customer satisfaction. We dive into the key factors that make us the top choice for towing in Salem.',
        slug: 'why-choose-salem-towing-service',
        tags: ['Towing', 'Salem', 'Customer Service'],
        metaDescription: 'Discover why Salem Towing Service is the top choice for towing in Salem, OR. Find out what makes our service reliable, professional, and customer-focused.',
        lastModified: new Date().toISOString()
    },
    {
        id: '2',
        title: 'Finding Affordable Towing in Salem',
        excerpt: 'Looking for affordable towing options in Salem, OR? Our guide helps you navigate the process of finding a towing service that offers both quality and affordability. Learn how to balance cost with the need for professional service. We share tips and strategies to help you make the best choice for your towing needs in Salem.',
        slug: 'affordable-towing-in-salem',
        tags: ['Affordable Towing', 'Salem', 'Guide'],
        metaDescription: 'Need affordable towing in Salem, OR? Check out our guide for tips on finding quality, budget-friendly towing services in your area.',
        lastModified: new Date().toISOString()
    },
    {
        id: '3',
        title: 'Ensuring Safety with Salem Towing Service',
        excerpt: 'At Salem Towing Service, we prioritize your safety and peace of mind. In this article, we discuss our commitment to safe towing practices and how we ensure the well-being of our customers in Salem, OR. Learn about our approach to handling emergency situations and how we strive to be a reliable and trustworthy towing service.',
        slug: 'ensuring-safety-with-salem-towing',
        tags: ['Safety', 'Towing Service', 'Salem'],
        metaDescription: 'Learn about Salem Towing Service\'s commitment to safety and customer peace of mind. Discover how we handle towing and roadside emergencies in Salem, OR.',
        lastModified: new Date().toISOString()
    }
]