import React from "react";
import {Link, Stack, Text} from "@chakra-ui/react";

export const BlogPost6 = () => {
    return (
        <Stack spacing={3} px={{base: 4, md: 0}}>
            <Text>Imagine this. You're driving down the road at night, down a road you don't recognize when
                you hit a
                pothole that's gone rogue. If it's done some significant damage, that'll pull you over
                pretty quickly.
                When you've got car trouble, and you're nowhere near home, you may go straight for your
                phone with the
                intention of calling your local towing company. But how do you decide which company to call?
                This
                article that we've put together will take you on a deep dive into the world of <Link
                    href={'/blog/roadside-assistance-beyond-towing'}>roadside assistance</Link>. What
                do we need to know, and how can it help us narrow down our choices of towing companies?
                Let's head over
                to step 1 and find out.</Text>
            <Text fontWeight={'bold'}>Flat Tire Changes:</Text>
            <Text>Getting a flat tire can really ruin your plans, no matter what you had going on before.
                Although if
                you have a good roadside assistance protocol in place, it can be the difference between a
                bad day and a
                good day. Choosing the right professionals to come and bail you out can be tricky. Luckily,
                Meriden
                Towing Service will make sure that your spare tire is swapped out and send you on your way
                with as
                little hassle as possible.</Text>
            <Text fontWeight={'bold'}>Battery Jump-Start:</Text>
            <Text>Dead batteries are no fun. You could be stopping at a place on the road to get lunch, come
                back out to
                see your lights were left on, and the battery died. We have drivers that can make it to you
                to fix up
                your battery and bring that car back to life. We want to get you back on the road to
                continue your
                adventure or reach a mechanic nearby.</Text>
            <Text fontWeight={'bold'}>Fuel Delivery:</Text>
            <Text>Try as you may; there might be a day where you're hauling down the road and can't seem to
                find the gas
                station. There you are, truck dead by the side of the highway. A quick call later, and there
                we are,
                right by your side to fuel you up and get you back on the right track so you make it to your
                destination.</Text>
            <Text fontWeight={'bold'}>Lockout Assistance:</Text>
            <Text>We've all had a time where you step out of the car, take a quick scan for your keys, and
                turn around
                slowly to find them sitting on the seat, staring right back at you. It happens to be the
                very same day
                that you remembered to lock each door when you left the car. But don't worry; we have a
                service for that
                too. If you reach out to us, not only can we be there before you know it to get that door
                open, you can
                avoid paying heavy locksmith fees.</Text>
            <Text fontWeight={'bold'}>Winching and Recovery:</Text>
            <Text>Getting stuck in the mud, snow, or other troublesome ground can be a pain, but you being
                stuck can be
                a thing of the past. Our drivers providing <Link
                    href={'/blog/roadside-assistance-beyond-towing'}>roadside
                    assistance</Link> can winch and recover that vehicle from
                terrain you would absolutely not want to walk through. You need to get unstuck, and we can
                make it
                happen! Our trucks have everything we need to pull you to safety and save you some time,
                potentially
                minimizing the extra damage the terrain could cause.</Text>
            <Text fontWeight={'bold'}>On-Site Repairs:</Text>
            <Text>There are some cases where minor repairs can be done at the scene of the accident; these
                could be
                common issues like a faulty alternator/loose connection with your battery, or a broken belt.
                All of our
                drivers are skilled <Link href={'/blog/roadside-assistance-beyond-towing'}>roadside
                    assistance</Link> technicians; we can save you the time and trouble of getting you
                over to a mechanic's shop, especially if it's later at night.</Text>
            <Text fontWeight={'bold'}>Conclusion</Text>
            <Text>Roadside assistance is always here to save you time and trouble; by offering you a range
                of services,
                we hope to take care of all your needs if you happen to be stranded. It could be a flat tire
                to
                jump-start, tow to winch rescue; whatever the emergency, we will get you rolling again. You
                have a ton
                of options to choose from, make sure you pick a reliable provider that can offer
                comprehensive service
                and takes pride in getting you out of harm's way through any unforeseen situations.</Text>
        </Stack>
    )
}