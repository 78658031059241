import React from "react";
import {Box, Heading, Link, ListItem, Stack, Text, UnorderedList} from "@chakra-ui/react";
import {SiteData} from "../../Constants/siteData";

export const FuelDelivery = () => {
    return (
        <Box>
            <Stack spacing={3} py={5}>
                <Heading as={'h3'}>Reliable Fuel Delivery Services in Meriden, CT</Heading>
                <Text>
                    Running out of gas can disrupt your day. That's where Meriden Towing Service comes in, offering
                    dependable fuel delivery services in Meriden, CT. Whether you're stranded on the side of the road or
                    parked at home, we ensure a quick fuel supply to get you back on track. Our service is perfect for
                    those unexpected moments when you need a little extra fuel to reach your destination.
                </Text>
                <Text>
                    Our fuel delivery service is tailored to your needs, providing fast and efficient assistance
                    whenever and wherever you need it.
                </Text>
                <UnorderedList spacing={3}>
                    <ListItem>
                        <b>Immediate Response:</b> We respond promptly to your call, minimizing your wait time and
                        inconvenience.
                    </ListItem>
                    <ListItem>
                        <b>Quality Fuel:</b> We provide high-quality fuel suitable for all types of vehicles, ensuring
                        optimal performance.
                    </ListItem>
                    <ListItem>
                        <b>Professional Service:</b> Our team is trained in safe fuel handling and delivery, ensuring a
                        risk-free experience.
                    </ListItem>
                    <ListItem>
                        <b>24/7 Availability:</b> Our services are available around the clock, ready to assist you any
                        time of the day or night.
                    </ListItem>
                </UnorderedList>
                <Text>
                    Choose Meriden Towing Service for fast and reliable fuel delivery services in Meriden, CT. We are
                    committed to getting you moving again with minimal disruption.
                </Text>
                <Heading as={'h4'}>Why Choose Our Fuel Delivery?</Heading>
                <Text>
                    Our fuel delivery service is a convenient solution for those unexpected out-of-gas situations,
                    saving you time and hassle. It's perfect for busy individuals, emergency situations, or when you are
                    far from a gas station.
                </Text>
                <Heading as={'h4'}>Comprehensive Roadside Assistance Services</Heading>
                <Text>
                    Beyond fuel delivery, we offer a wide range of roadside assistance services, including <Link
                    href={"/emergency-towing"}>emergency towing</Link>, <Link href={"/jump-start-service"}>jump start
                    service</Link>, <Link href={"/car-lockout-service"}>car lockout services</Link>, and more. Our goal
                    is to be your go-to provider for all roadside emergencies.
                </Text>
                <Text>
                    Contact Meriden Towing Service for reliable fuel delivery and comprehensive roadside assistance in
                    Meriden, CT.
                </Text>
                <Text>
                    <Link color="teal.500" href={SiteData.telLink}>
                        Call us at {SiteData.phoneNumber} for immediate assistance.
                    </Link>
                </Text>
            </Stack>
        </Box>
    )
}