import React from "react";
import {Box, Heading, ListItem, Stack, Text, UnorderedList, Link} from "@chakra-ui/react";
import {SiteData} from "../../Constants/siteData";

export const LongDistanceTowing = () => {
    return (
        <Box>
            <Stack spacing={3} py={5}>
                <Heading as={'h3'}>Expert Long Distance Towing Services in Meriden, CT</Heading>
                <Text>
                    When it comes to transporting vehicles over long distances, trust and reliability are key. Meriden
                    Towing Service offers professional long distance towing services in Meriden, CT, ensuring your
                    vehicle is transported safely and securely, no matter the distance. Whether you're relocating,
                    purchasing a vehicle from afar, or need to transport a vehicle for any other reason, our team is
                    equipped to provide top-tier long distance towing solutions.
                </Text>
                <Text>
                    Our long distance towing services are designed to meet your specific needs. We understand the
                    challenges and logistics involved in transporting vehicles over extended distances and are committed
                    to providing a stress-free experience for you.
                </Text>
                <UnorderedList spacing={3}>
                    <ListItem>
                        <b>Customized Towing Plans:</b> We work closely with you to create a tailored towing plan that
                        fits your specific requirements, schedule, and budget.
                    </ListItem>
                    <ListItem>
                        <b>State-of-the-Art Equipment:</b> Our fleet of tow trucks is equipped with the latest
                        technology to ensure the safe and efficient transportation of your vehicle.
                    </ListItem>
                    <ListItem>
                        <b>Skilled and Experienced Drivers:</b> Our drivers are highly trained and experienced in long
                        distance towing, ensuring that your vehicle is in safe hands throughout its journey.
                    </ListItem>
                    <ListItem>
                        <b>Comprehensive Service:</b> From the initial pickup to the final delivery, we handle every
                        aspect of the towing process, providing you with peace of mind.
                    </ListItem>
                    <ListItem>
                        <b>24/7 Availability:</b> Our services are available around the clock, allowing us to
                        accommodate your schedule and provide assistance whenever needed.
                    </ListItem>
                </UnorderedList>
                <Text>
                    Choose Meriden Towing Service for reliable and efficient long distance towing services in Meriden,
                    CT. We're dedicated to delivering your vehicle safely and on time.
                </Text>
                <Heading as={'h4'}>Why Long Distance Towing?</Heading>
                <Text>
                    Long distance towing is the ideal solution for various scenarios, including vehicle relocation,
                    online vehicle purchases, and moving classic or luxury cars. It offers a safe and convenient way to
                    transport vehicles without the wear and tear of driving them over long distances.
                </Text>
                <Heading as={'h4'}>Our Full Range of Towing and Roadside Assistance Services</Heading>
                <Text>
                    In addition to long distance towing, we offer a comprehensive range of towing and roadside
                    assistance services. This includes <Link href={"/emergency-towing"}>emergency towing</Link>, <Link
                    href={"/roadside-assistance"}>roadside assistance</Link>, <Link href={"/vehicle-recovery"}>vehicle
                    recovery</Link>, <Link href={"/flatbed-towing"}>flatbed towing</Link>, and <Link
                    href={"/car-lockout-service"}>car lockout services</Link>. Our goal is to be your one-stop solution
                    for all your vehicle transportation and emergency needs.
                </Text>
                <Text>
                    Whether you need immediate roadside assistance or a long-haul tow, Meriden Towing Service is here to
                    provide professional, reliable, and efficient service.
                </Text>
                <Heading as={'h4'}>Contact Us for Dependable Long Distance Towing</Heading>
                <Text>
                    For top-quality long distance towing services in Meriden, CT, contact Meriden Towing Service. We're
                    ready to ensure your vehicle's safe and timely transportation.
                </Text>
                <Text>
                    <Link color="teal.500" href={SiteData.telLink}>
                        Call us at {SiteData.phoneNumber} for a customized towing solution.
                    </Link>
                </Text>
                <Text>
                    Rely on us for all your long distance towing needs and experience the professionalism and care that
                    sets us apart.
                </Text>
            </Stack>
        </Box>
    )
}