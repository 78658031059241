import React from "react";
import {Box, Heading, Link, ListItem, Stack, Text, UnorderedList} from "@chakra-ui/react";
import {SiteData} from "../../Constants/siteData";

export const VehicleRecovery = () => {
    return (
        <Box>
            <Stack spacing={3} py={5}>
                <Heading as={'h3'}>Professional Vehicle Recovery in Salem, OR</Heading>
                <Text>
                    Salem Towing Service offers expert vehicle recovery services in Salem, OR. Whether you’ve been in an
                    accident or your vehicle is stuck off-road, our team is equipped to provide safe and efficient
                    recovery.
                </Text>
                <Text>
                    We handle each recovery situation with the utmost care, ensuring the safety of your vehicle.
                </Text>
                <UnorderedList spacing={3}>
                    <ListItem>
                        <b>Safe Recovery Techniques:</b> Our team uses advanced methods and equipment for safe vehicle
                        recovery.
                    </ListItem>
                    <ListItem>
                        <b>Experienced Professionals:</b> Our technicians are experienced in handling complex recovery
                        scenarios.
                    </ListItem>
                    <ListItem>
                        <b>Rapid Response:</b> We respond quickly to your call, reducing your wait time and stress.
                    </ListItem>
                    <ListItem>
                        <b>All Vehicle Types:</b> We are capable of recovering a wide range of vehicles, regardless of
                        their condition.
                    </ListItem>
                    <ListItem>
                        <b>Customer Focus:</b> Your satisfaction is our priority; we ensure a smooth and hassle-free
                        recovery process.
                    </ListItem>
                </UnorderedList>
                <Text>
                    For reliable vehicle recovery services in Salem, OR, turn to Salem Towing Service. We are prepared
                    to handle your vehicle recovery needs with precision and care.
                </Text>
                <Heading as={'h4'}>Contact Us for Vehicle Recovery Services</Heading>
                <Text>
                    In need of vehicle recovery in Salem, OR? Reach out to us for professional and efficient service.
                    Our team is ready to assist you in your time of need.
                </Text>
                <Text>
                    <Link color="teal.500" href={SiteData.telLink}>
                        Call {SiteData.phoneNumber} for expert vehicle recovery.
                    </Link>
                </Text>
                <Text>
                    Trust in Salem Towing Service for all your vehicle recovery needs, ensuring the safe and careful
                    handling of your vehicle.
                </Text>
            </Stack>
        </Box>
    )
}