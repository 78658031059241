import React from "react";
import {Box, Heading, Link, ListItem, Stack, Text, UnorderedList} from "@chakra-ui/react";
import {SiteData} from "../../Constants/siteData";

export const JumpStart = () => {
    return (
        <Box>
            <Stack spacing={3} py={5}>
                <Heading as={'h3'}>Expert Jump Start Services in Meriden, CT</Heading>
                <Text>
                    A dead battery can leave you stranded, but there's no need to worry. Meriden Towing Service provides
                    expert jump start services in Meriden, CT. Our skilled technicians are equipped to handle all types
                    of vehicles, ensuring a quick and safe solution to get your car running again. Whether you're at
                    home, work, or on the road, we're here to help.
                </Text>
                <Text>
                    We tailor our jump start services to meet your situation, offering a swift response to get you back
                    on your way.
                </Text>
                <UnorderedList spacing={3}>
                    <ListItem>
                        <b>Efficient Service:</b> Our team arrives quickly and gets your vehicle started in no time.
                    </ListItem>
                    <ListItem>
                        <b>Safe Procedures:</b> We use the safest methods to protect your vehicle's electrical system
                        during the jump start process.
                    </ListItem>
                    <ListItem>
                        <b>Experienced Technicians:</b> Our professionals have extensive experience with all vehicle
                        makes and models.
                    </ListItem>
                    <ListItem>
                        <b>24/7 Assistance:</b> Our jump start services are available any time, day or night, ensuring
                        help is always at hand.
                    </ListItem>
                </UnorderedList>
                <Text>
                    Trust Meriden Towing Service for prompt and professional jump start services in Meriden, CT. We're
                    dedicated to getting you moving without delay.
                </Text>
                <Heading as={'h4'}>Why Jump Start Service?</Heading>
                <Text>
                    Our jump start service is essential for quickly resolving dead battery issues, ensuring you don't
                    miss important appointments or get stuck in unsafe locations.
                </Text>
                <Heading as={'h4'}>All-Inclusive Roadside Assistance Services</Heading>
                <Text>
                    In addition to jump starts, our comprehensive roadside assistance includes <Link
                    href={"/emergency-towing"}>emergency towing</Link>, <Link href={"/fuel-delivery"}>fuel
                    delivery</Link>, <Link href={"/car-lockout-service"}>car lockout services</Link>, and more. We aim
                    to provide a complete solution for all your roadside needs.
                </Text>
                <Text>
                    For fast and efficient jump start services in Meriden, CT, reach out to Meriden Towing Service.
                </Text>
                <Text>
                    <Link color="teal.500" href={SiteData.telLink}>
                        Dial {SiteData.phoneNumber} for immediate assistance.
                    </Link>
                </Text>
            </Stack>
        </Box>
    )
}