import React from "react";
import {Box, Heading, Link, ListItem, Stack, Text, UnorderedList} from "@chakra-ui/react";
import {SiteData} from "../../Constants/siteData";

export const RoadsideAssistance = () => {
    return (
        <Box>
            <Stack spacing={3} py={5}>
                <Heading as={'h3'}>Expert Roadside Assistance in Salem, OR</Heading>
                <Text>
                    Salem Towing Service offers comprehensive roadside assistance to address any unexpected issues you
                    encounter on the road in Salem, OR. From flat tires and dead batteries to lockouts and fuel
                    shortages, our skilled team is equipped to handle a wide array of roadside emergencies.
                </Text>
                <Text>
                    We are committed to delivering quick, efficient, and courteous service to help you resume your
                    journey with minimal disruption.
                </Text>
                <UnorderedList spacing={3}>
                    <ListItem>
                        <b>Round-the-Clock Support:</b> Our roadside assistance services are available 24/7, ensuring
                        you're never left stranded.
                    </ListItem>
                    <ListItem>
                        <b>Diverse Solutions:</b> We cover everything from tire changes and battery jump-starts to
                        emergency fuel delivery and car lockout solutions.
                    </ListItem>
                    <ListItem>
                        <b>Experienced Professionals:</b> Our team comprises trained experts equipped to address various
                        roadside emergencies safely and effectively.
                    </ListItem>
                    <ListItem>
                        <b>Rapid Response:</b> We understand the urgency of roadside issues and strive to reach you
                        promptly to reduce your waiting time.
                    </ListItem>
                    <ListItem>
                        <b>Customer-Focused Approach:</b> Your safety and satisfaction are our top priorities, and we
                        tailor our services to meet your specific needs in any roadside situation.
                    </ListItem>
                </UnorderedList>
                <Text>
                    For dependable roadside assistance in Salem, OR, turn to Salem Towing Service. We are here to
                    provide the support and care you need in times of trouble.
                </Text>
                <Heading as={'h4'}>Contact Us for Immediate Roadside Support</Heading>
                <Text>
                    If you need roadside assistance in Salem, OR, don't hesitate to contact us. Our team is ready to
                    offer rapid and effective solutions to your roadside emergencies.
                </Text>
                <Text>
                    <Link color="teal.500" href={SiteData.telLink}>
                        Call us at {SiteData.phoneNumber} for prompt roadside assistance.
                    </Link>
                </Text>
                <Text>
                    Rely on Salem Towing Service for all your roadside assistance needs and enjoy the peace of mind that
                    comes with our expert service.
                </Text>
            </Stack>
        </Box>
    )
}