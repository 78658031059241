import React from "react";
import {Box, Heading, Link, ListItem, Stack, Text, UnorderedList} from "@chakra-ui/react";
import {SiteData} from "../../Constants/siteData";

export const EmergencyTowing = () => {
    return (
        <Box>
            <Stack spacing={3} py={5}>
                <Heading as={'h3'}>Reliable Emergency Towing in Salem, OR</Heading>
                <Text>
                    At Salem Towing Service, we offer swift and dependable emergency towing services in Salem, OR. Our
                    team is ready to respond to your towing needs at any hour, ensuring you're never stranded. Whether
                    it's a breakdown, accident, or any other urgent situation, we're equipped to provide efficient
                    towing services.
                </Text>
                <Text>
                    We prioritize safety and speed, understanding the importance of getting you and your vehicle to
                    safety as quickly as possible.
                </Text>
                <UnorderedList spacing={3}>
                    <ListItem>
                        <b>24/7 Emergency Service:</b> Our towing services are available around the clock, providing
                        peace of mind that help is always just a call away.
                    </ListItem>
                    <ListItem>
                        <b>Fast and Efficient:</b> We understand the urgency of your situation and strive to provide
                        prompt towing services to minimize your wait time and stress.
                    </ListItem>
                    <ListItem>
                        <b>Professional Handling:</b> Our team of experienced professionals ensures your vehicle is
                        towed safely and securely, regardless of the situation.
                    </ListItem>
                    <ListItem>
                        <b>Wide Service Range:</b> We handle all types of vehicles, offering both local and
                        long-distance towing solutions.
                    </ListItem>
                    <ListItem>
                        <b>Customer-Centric Service:</b> At Salem Towing Service, we focus on your needs, offering
                        personalized service to ensure your complete satisfaction.
                    </ListItem>
                </UnorderedList>
                <Text>
                    In need of emergency towing in Salem, OR? Trust Salem Towing Service for fast, reliable, and
                    professional towing services.
                </Text>
                <Heading as={'h4'}>Contact Us for Immediate Towing Assistance</Heading>
                <Text>
                    If you require emergency towing in Salem, OR, reach out to us without delay. Our team is prepared to
                    provide quick and effective towing solutions tailored to your situation.
                </Text>
                <Text>
                    <Link color="teal.500" href={SiteData.telLink}>
                        Call us at {SiteData.phoneNumber} for swift and safe towing services.
                    </Link>
                </Text>
                <Text>
                    Depend on Salem Towing Service for all your emergency towing needs and experience the assurance of
                    our expert service.
                </Text>
            </Stack>
        </Box>
    )
}