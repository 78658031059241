import React from "react";
import {Box, Heading, Link, ListItem, Stack, Text, UnorderedList} from "@chakra-ui/react";
import {SiteData} from "../../Constants/siteData";

export const CarLockoutService = () => {
    return (
        <Box>
            <Stack spacing={3} py={5}>
                <Heading as={'h3'}>Efficient Car Lockout Services in Salem, OR</Heading>
                <Text>
                    Locked out of your car? Salem Towing Service provides fast and efficient car lockout services in
                    Salem, OR. Our expert team is equipped with the latest tools to unlock your vehicle quickly and
                    without any damage.
                </Text>
                <Text>
                    We understand the urgency of being locked out, and our priority is to get you back into your vehicle
                    as soon as possible.
                </Text>
                <UnorderedList spacing={3}>
                    <ListItem>
                        <b>Quick Response:</b> Our team is known for rapid response, ensuring that you’re not left
                        waiting.
                    </ListItem>
                    <ListItem>
                        <b>Damage-Free Techniques:</b> We use safe, effective methods to unlock your vehicle without
                        causing damage.
                    </ListItem>
                    <ListItem>
                        <b>24/7 Availability:</b> Our lockout services are available day and night, so you’re never
                        stranded.
                    </ListItem>
                    <ListItem>
                        <b>Experienced Technicians:</b> Our professionals are skilled in handling a variety of lockout
                        situations.
                    </ListItem>
                    <ListItem>
                        <b>Customer-Centric Service:</b> We focus on providing a stress-free experience, ensuring your
                        satisfaction.
                    </ListItem>
                </UnorderedList>
                <Text>
                    For immediate car lockout assistance in Salem, OR, choose Salem Towing Service. We’re here to help
                    you quickly and safely regain access to your vehicle.
                </Text>
                <Heading as={'h4'}>Contact Us for Car Lockout Assistance</Heading>
                <Text>
                    Need help with a car lockout in Salem, OR? Contact us right away. Our team is ready to provide you
                    with swift and secure lockout solutions.
                </Text>
                <Text>
                    <Link color="teal.500" href={SiteData.telLink}>
                        Call {SiteData.phoneNumber} for rapid car lockout service.
                    </Link>
                </Text>
                <Text>
                    Rely on Salem Towing Service for efficient and reliable car lockout services.
                </Text>
            </Stack>
        </Box>
    )
}