import React from "react";
import {Box, Heading, Link, ListItem, Stack, Text, UnorderedList} from "@chakra-ui/react";
import {SiteData} from "../../Constants/siteData";

export const FlatTire = () => {
    return (
        <Box>
            <Stack spacing={3} py={5}>
                <Heading as={'h3'}>Efficient Flat Tire Services in Salem, OR</Heading>
                <Text>
                    Encounter a flat tire? Salem Towing Service in Salem, OR, is here to assist. We provide fast and
                    efficient flat tire services, including tire changes and repairs. Our team ensures you’re back on
                    the road safely and promptly.
                </Text>
                <Text>
                    With our expertise and equipment, no flat tire situation is too challenging for us to handle.
                </Text>
                <UnorderedList spacing={3}>
                    <ListItem>
                        <b>Rapid Response:</b> We arrive quickly to address your flat tire, minimizing your downtime.
                    </ListItem>
                    <ListItem>
                        <b>Quality Service:</b> Our experienced technicians provide top-quality tire changes and
                        repairs.
                    </ListItem>
                    <ListItem>
                        <b>All Vehicle Types:</b> We are equipped to handle flat tire situations for a wide range of
                        vehicles.
                    </ListItem>
                    <ListItem>
                        <b>24/7 Availability:</b> Our services are available around the clock, so you’re never left
                        stranded.
                    </ListItem>
                    <ListItem>
                        <b>Customer Satisfaction:</b> We focus on providing a hassle-free service, ensuring your
                        complete satisfaction.
                    </ListItem>
                </UnorderedList>
                <Text>
                    Need flat tire services in Salem, OR? Salem Towing Service is your go-to solution for quick and
                    reliable tire assistance.
                </Text>
                <Heading as={'h4'}>Contact Us for Flat Tire Services</Heading>
                <Text>
                    If you're dealing with a flat tire in Salem, OR, don't hesitate to contact us. We're ready to
                    provide fast and effective tire solutions.
                </Text>
                <Text>
                    <Link color="teal.500" href={SiteData.telLink}>
                        Call {SiteData.phoneNumber} for immediate assistance with your flat tire.
                    </Link>
                </Text>
                <Text>
                    Rely on Salem Towing Service for all your flat tire needs, ensuring you're back on the road with
                    safety and ease.
                </Text>
            </Stack>
        </Box>
    )
}