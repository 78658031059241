import React from "react";
import towingMeta from '../assets/images/meridenmeta.jpg'
import bgImage from '../assets/images/towing10.jpg'
import {posts} from "./posts";
import {Box, Button, Heading, Hide, Link, List, ListItem, Stack, Text, UnorderedList} from "@chakra-ui/react";
import {GiCarBattery, GiFlatTire, GiFuelTank, GiPathDistance, GiTowTruck} from "react-icons/gi";
import {BsTruckFlatbed} from "react-icons/bs";
import {BiLockOpenAlt} from "react-icons/bi";
import {MdEmergencyShare} from "react-icons/md";
import {TbRoad} from "react-icons/tb";
import {CarLockoutService} from "../Components/CarLockoutService/CarLockoutService";
import {VehicleRecovery} from "../Components/VehicleRecovery/VehicleRecovery";
import {LongDistanceTowing} from "../Components/LongDistanceTowing/LongDistanceTowing";
import {FlatbedTowing} from "../Components/FlatbedTowing/FlatbedTowing";
import {RoadsideAssistance} from "../Components/RoadsideAssistance/RoadsideAssistance";
import {EmergencyTowing} from "../Components/EmergencyTowing/EmergencyTowing";
import {FlatTire} from "../Components/FlatTire/FlatTire";
import {JumpStart} from "../Components/JumpStartService/JumpStart";
import {FuelDelivery} from "../Components/FuelDelivery/FuelDelivery";
import {RiEmotionHappyLine} from "react-icons/ri";
import {PiClockCountdownFill} from "react-icons/pi";

const phoneNumber =  '(971) 252-7679';
const telLink = 'tel:9712527679';
const phoneNumberWithDashes = '971-252-7679';

export const SiteData = {
    hoursOfOpperation: "24/7",
    city: 'Salem, OR',
    phoneNumber,
    telLink,
    phoneNumberWithDashes,
    title: `Towing Service in Salem, OR | 24-Hour Towing | ${phoneNumberWithDashes}`,
    buttonCtaText: 'Call Now for 24/7 Emergency Towing Assistance!',
    keywords: 'Towing, services, affordable, friendly, easy, cheap, flatbed, Salem, OR.',
    footerText: 'Salem Towing Service, All rights reserved.',
    ogImage: towingMeta,
    navLinks: [
        {
            title: 'Home',
            href: '/'
        },
        {
            title: 'Services',
            href: '/services'
        },
        {
            title: 'Blog',
            href: '/blog'
        },
        {
            title: 'About',
            href: '/about'
        },
        {
            title: 'Contact Us',
            href: '/contact'
        },
        {
            title: 'Careers',
            href: '/careers',
            hideFromTopNav: true
        },
    ],
    signupForm: {
        intro: () => <Text>Join Salem's premier towing service community. Stay updated on the latest in towing
            technology and services in Salem. We promise to keep your vehicle moving safely!</Text>,
        servicesTitle: 'Our Services:',
    },
    homepage: {
        url: 'https://www.salemtowtruck.com',
        bgImage: bgImage,
        metaData: {
            title: `Salem Towing Services: Your Trusted 24/7 Towing Partner in Salem, OR | Call ${phoneNumberWithDashes}`,
            description: 'Dependable and quick 24/7 towing services in Salem, Oregon. We offer emergency towing, roadside assistance, vehicle recovery, and more. Contact us for prompt and professional help.',
        },
        h1: '24/7 Towing Services in Salem, Oregon',
        subtitle: 'Reliable Roadside Assistance for Every Occasion',
        servicesTitle: 'Comprehensive Towing Services in Salem',
        servicesHeader: 'Dedicated to Providing Exceptional Service',
        titleHead: 'Salem’s Choice for Towing and Roadside Assistance',
        sectionTitle: 'Trustworthy, Efficient, and Rapid Towing Services',
        content: [
            <>
                <Text>
                    <Heading as="h2" size={'md'} pb='2'>Welcome to Salem Towing Service, Your Local Towing
                        Experts</Heading>
                    Welcome to Salem Towing Service, your go-to source for professional and reliable towing services in
                    Salem, OR. Situated conveniently in the city, we specialize in offering a comprehensive range of
                    towing solutions tailored to meet the specific needs of our community. Whether you're faced with a
                    vehicle breakdown, lockout, or need a simple tow, our team is renowned for prompt response,
                    reliability, and affordable pricing.
                </Text>

                <Text>
                    <Heading as="h2" size={'md'} pb='2'>Round-the-Clock Towing Services in Salem</Heading>
                    Understanding that vehicle troubles can happen at any time, Salem Towing Service offers 24/7
                    emergency assistance. Day or night, our experienced team is prepared to provide fast and reliable
                    support. We prioritize your safety and convenience, ensuring timely help whenever you need it.
                </Text>

                <Text>
                    <Heading as="h2" size={'md'} pb='2'>Comprehensive Range of Towing and Assistance Services</Heading>
                    Salem Towing Service offers a wide array of services to address all your roadside needs efficiently.
                    Our experienced professionals are equipped with the latest tools and technology to ensure the safe
                    and secure handling of your vehicle. We are committed to providing quick, efficient, and
                    cost-effective solutions.
                    <UnorderedList>
                        <ListItem><Link href={"/emergency-towing"}>Emergency Towing</Link>: Rapid response for urgent
                            towing needs, available 24/7.</ListItem>
                        <ListItem><Link href={"/roadside-assistance"}>Roadside Assistance</Link>: Comprehensive help for
                            issues like flat tires, battery problems, and more.</ListItem>
                        <ListItem><Link href={"/vehicle-recovery"}>Vehicle Recovery</Link>: Expert services for
                            post-accident vehicle recovery and more.</ListItem>
                        <ListItem><Link href={"/long-distance-towing"}>Long Distance Towing</Link>: Safe and secure
                            towing services for long distances.</ListItem>
                        <ListItem><Link href={"/flatbed-towing"}>Flatbed Towing</Link>: Ideal for transporting luxury,
                            exotic, or damaged vehicles.</ListItem>
                        <ListItem><Link href={"/car-lockout-service"}>Car Lockout Services</Link>: Quick solutions for
                            when you're locked out of your car.</ListItem>
                        <ListItem><Link href={"/flat-tire-services"}>Flat Tire Services</Link>: Fast tire changes and
                            repairs to get you back on the road.</ListItem>
                        <ListItem><Link href={"/fuel-delivery"}>Fuel Delivery Services</Link>: Prompt delivery of fuel
                            when you run out on the road.</ListItem>
                        <ListItem><Link href={"/jump-start-service"}>Jump Start Services</Link>: Revive your vehicle's
                            dead battery with our quick jump start service.</ListItem>
                    </UnorderedList>
                </Text>

                <Text>
                    <Heading as="h2" size={'md'} pb='2'>Why Choose Salem Towing Service?</Heading>
                    Choosing Salem Towing Service means opting for a trusted partner committed to your safety and
                    satisfaction. Our transparent pricing, combined with exceptional service, makes us a preferred
                    choice in Salem. We believe in delivering quality service that meets your needs without hidden costs
                    or complications.
                </Text>

                <Text>
                    <Heading as="h2" size={'md'} pb='2'>Contact Us Today</Heading>
                    For dependable towing and roadside assistance in Salem, OR, look no further than Salem Towing
                    Service. Reach out to us at {phoneNumber} for immediate assistance or learn more about our services
                    on our <Link href={'/contact'} textDecoration={'underline'}>contact page</Link>. We're here to
                    provide you with quick, reliable, and friendly service whenever you need it.
                </Text>
                <Button as={'a'} href={telLink} bg={'#17b644'} fontSize={{base: '20px', md: '23px'}}
                        color={'white'} fontWeight={'bold'}
                        _hover={{bg: '#06c03b', color: 'white', cursor: 'pointer'}}
                        p={'16px 30px'} borderRadius={0} mt={'2rem'}
                        lineHeight={'26px'}
                        minH={'66px'}
                        width={'100%'}
                        maxW={'400px'}><i className="fa fa-phone" aria-hidden="true"/>&nbsp;&nbsp;Call for <Hide below='md'>Immediate</Hide> Assistance</Button>
            </>
        ],
        values: [
            {id: 0, number: "10,000+", name: 'Vehicles Towed', icon: GiTowTruck},
            {id: 1, number: "24/7", name: 'Service Availability', icon: MdEmergencyShare},
            {id: 2, number: "98%", name: 'Customer Satisfaction', icon: RiEmotionHappyLine},
            {id: 3, number: "30min", name: 'Average Response Time', icon: PiClockCountdownFill}
        ],
        servicesSubtitle: () => <Text color="muted" fontSize={{base: 'lg', md: 'xl'}}>Explore our full range of services
            at <Link href={'/services'}>Salem Towing Services</Link> – from emergency towing to comprehensive roadside
            support, we're your all-in-one solution for any roadside scenario.</Text>,
        featuredServices: [
            {
                name: '24 Hour Emergency Towing',
                description: 'Immediate response to all emergency towing situations in and around Salem. Whether you’re stuck on the highway or in your driveway, we’re here to assist.',
                icon: 'MdEmergencyShare',
                path: '/emergency-towing'
            },
            {
                name: 'Roadside Assistance',
                description: 'From flat tires to battery jumps, our roadside assistance team is equipped to handle a variety of situations, ensuring you’re back on the road in no time.',
                icon: 'TbRoad',
                path: '/roadside-assistance'
            },
            {
                name: 'Vehicle Recovery',
                description: 'Expert vehicle recovery services for accidents and off-road incidents. We provide safe and careful recovery, prioritizing the condition of your vehicle.',
                icon: 'GiTowTruck',
                path: '/vehicle-recovery'
            },
            {
                name: 'Long-Distance Towing',
                description: 'Need to transport your vehicle over a long distance? Our long-distance towing services offer secure transportation for your vehicle across state lines or further.',
                icon: 'GiPathDistance',
                path: '/long-distance-towing'
            },
            {
                name: 'Flatbed Towing',
                description: 'Specialized flatbed towing services ideal for high-end, luxury, or damaged vehicles. We ensure your vehicle is transported safely and securely.',
                icon: 'BsTruckFlatbed',
                path: '/flatbed-towing'
            },
            {
                name: 'Car Lockout Service',
                description: 'Locked out of your car? Our skilled technicians provide quick, damage-free lockout services to help you regain access to your vehicle.',
                icon: 'BiLockOpenAlt',
                path: '/car-lockout-service'
            },
            {
                name: 'Flat Tire Services',
                description: 'Flat or damaged tire? Our team arrives quickly to replace or repair your tire, ensuring you can continue your journey safely.',
                icon: 'GiFlatTire',
                path: '/flat-tire-services'
            },
            {
                name: 'Fuel Delivery Services',
                description: 'Run out of gas? Our fuel delivery service promptly brings you the fuel you need to get back on the road.',
                icon: 'GiFuelTank',
                path: '/fuel-delivery'
            },
            {
                name: 'Jump Start Services',
                description: 'Dead battery? We arrive swiftly to jump-start your vehicle, helping you avoid prolonged delays and inconvenience.',
                icon: 'GiCarBattery',
                path: '/jump-start-service'
            }
        ],
        services: [
            {
                id: '0',
                title: 'Rapid Response',
                text: () => <Text> In any roadside emergency, call us 24/7 for fast help when you need it most.</Text>
            },
            {
                id: '1',
                title: 'Experienced Professionals',
                text: () => <Text>Our team of certified, licensed, and insured professionals are trained to handle any
                    of your roadside assistance needs.</Text>
            },
            {
                id: '2',
                title: 'Affordable Rates',
                text: () => <Text>High-quality towing service at competitive prices. We believe in fair, transparent
                    pricing.</Text>
            },
            {
                id: '3',
                title: '24/7 Towing Service',
                text: () => <Text>Day or night we have you covered. With our towing service, you'll never be stranded in
                    Salem or the surrounding areas.</Text>
            },
            {
                id: '4',
                title: 'Trustworthy & Reliable',
                text: () => <Text>As a local business, our reputation is everything. Our trained team of professionals
                    strive to deliver unmatched towing service.</Text>
            }
        ],
        footerHeading: 'Contact Salem Towing Services',
        footerText: () => <>For reliable roadside assistance in Salem and the surrounding areas, reach out to us 24/7.
            We are available to help in any roadside emergency. <Link href={telLink}>Call us</Link> or fill out
            our <Link href={'/contact'}>online contact form</Link> for non-emergency inquiries. Salem Towing Services is
            your roadside trouble's best solution.</>,
        footerText2: 'Your Safety and Satisfaction is Our Priority',
    },
    careers: {
        url: "https://www.salemtowtruck.com/careers/",
        metaData: {
            title: "Join Our Team - Career Opportunities at Salem Towing Services, Salem, OR",
            description: "Explore rewarding career opportunities at Salem Towing Services. Join our team of professional towing service providers in Salem, OR."
        },
        h1: "We're Hiring - Become a Part of Salem Towing Services",
        h2: [
            "Why Join Salem Towing Services?",
            "Current Openings for Towing Professionals",
            "Our Commitment to Employee Growth and Satisfaction"
        ],
        headingText: {
            whyJoin: "Join a team dedicated to excellence in towing and roadside assistance. We offer a dynamic, supportive work environment where your skills and dedication are valued.",
            openings: "We are looking for motivated individuals passionate about providing exceptional service. Discover various roles, from tow truck drivers to customer service representatives.",
            commitment: "At Salem Towing Services, we're committed to our team's professional growth. Enjoy comprehensive training, competitive compensation, and a supportive work culture."
        },
        "content": [
            <Text>Welcome to the Careers section at <Link href={'/'} color="teal.500">Salem Towing Service</Link>, where
                your career in towing and roadside assistance can flourish. We're more than just a team; we're a family
                dedicated to excellence in every aspect of our service. Join us in making a meaningful impact in Salem,
                OR.</Text>,
            <Text>At <Link href={'/'} color="teal.500">Salem Towing Service</Link>, we believe our strength lies in our
                dedicated team. We're seeking professionals who are passionate about delivering outstanding towing
                services. Whether you're a seasoned tow truck operator or looking to start your career, we offer a
                dynamic workplace where your skills will grow and be appreciated.</Text>,
            <Text>Our employees enjoy a supportive culture that fosters both personal and professional growth. We
                provide comprehensive training, competitive compensation, and work with state-of-the-art equipment. Our
                goal is to create a safe, rewarding, and enjoyable workplace for all team members.</Text>,
            <Text>As a vital part of the Salem community, we take pride in serving our neighbors with integrity and
                professionalism. Our commitment to service excellence extends to our team, whom we support with a
                positive work environment, respectful teamwork, and opportunities for career advancement.</Text>,
            <Text>Ready to join a leading towing service provider in Salem, OR? Explore our current job openings and
                discover where you fit in our expanding team. From driving and roadside assistance to customer service
                and administrative roles, there's an opportunity for you at Salem Towing Service.</Text>,
            <Text><Link href={'https://www.salemtowtruck.com/careers/'} color="teal.500">Discover your next career
                opportunity</Link> with us. Be a part of a team that's transforming the towing industry with every tow,
                every repair, and every satisfied customer. At Salem Towing Service, we're not just a company; we're a
                community. Apply today and steer your career in a new direction.</Text>,
        ]
    },
    "about": {
        url: "https://www.salemtowtruck.com/about/",
        metaData: {
            title: "About Salem Towing Services - Your Trusted Towing Experts in Salem, OR",
            description: "Discover more about Salem Towing Services and our commitment to providing the best towing and roadside assistance in Salem, Oregon."
        },
        h1: "About Salem Towing Services",
        h2: [
            "Our Mission and Vision",
            "Expert Team, Expert Service",
            "A Wide Range of Towing and Recovery Services"
        ],
        "headingText": {
            "commitment": "Dedicated to delivering exceptional towing and roadside support to the Salem community.",
            "team": "Our team of professionals is highly trained and experienced, prepared to deliver top-quality service in every call.",
            "services": "Discover our extensive range of towing solutions, tailored to meet every roadside and vehicle recovery scenario."
        },
        "services": [
            {
                "id": "0",
                title: '24 Hour Emergency Towing',
                href: '/emergency-towing',
                icon: MdEmergencyShare,
                description: <Text>Our team is ready to respond to emergencies around the clock, offering fast and
                    reliable towing services throughout Salem, OR, day and night.</Text>
            },
            {
                "id": "1",
                title: 'Roadside Assistance',
                href: '/roadside-assistance',
                icon: TbRoad,
                description: <Text>Whenever you need a helping hand on the road, count on us for dependable roadside
                    assistance, from jump-starts to tire changes and lockout services.</Text>
            },
            {
                "id": "2",
                title: 'Vehicle Recovery',
                href: '/vehicle-recovery',
                icon: GiTowTruck,
                description: <Text>Efficient and careful, our vehicle recovery services are designed to retrieve your
                    vehicle from any challenging situation with minimal hassle.</Text>
            },
            {
                "id": "3",
                title: 'Long-Distance Towing',
                href: '/long-distance-towing',
                icon: GiPathDistance,
                description: <Text>Specializing in long-distance towing, we provide secure transport for your vehicle to
                    any destination, ensuring safety and punctuality.</Text>
            },
            {
                "id": "4",
                title: 'Flatbed Towing',
                href: '/flatbed-towing',
                icon: BsTruckFlatbed,
                description: <Text>For high-value vehicles, trust our flatbed towing service to transport your vehicle
                    without risk of damage or wear.</Text>
            },
            {
                "id": "5",
                title: 'Car Lockout Service',
                href: '/car-lockout-service',
                icon: BiLockOpenAlt,
                description: <Text>Locked out of your car? Our car lockout service is quick, efficient, and damage-free,
                    ensuring you regain access to your vehicle promptly.</Text>
            },
            {
                id: "6",
                title: "Flat Tire Service",
                href: "/flat-tire-services",
                icon: GiFlatTire,
                description: <Text>If you're stranded with a flat tire, our team is ready to provide quick tire changing
                    and repair services, getting you back on the road safely and swiftly.</Text>
            },
            {
                id: "7",
                title: "Fuel Delivery Service",
                href: "/fuel-delivery",
                icon: GiFuelTank,
                description: <Text>Run out of gas? Our fuel delivery service is here to help, bringing fuel directly to
                    you and ensuring you can continue your journey with minimal interruption.</Text>
            },
            {
                id: "8",
                title: "Jump Start Service",
                href: "/jump-start-service",
                icon: GiCarBattery,
                description: <Text>Dealing with a dead battery? Count on our jump start service for a fast and effective
                    solution to get your vehicle running again smoothly.</Text>
            }
        ]
    },
    services: {
        url: 'https://www.salemtowtruck.com/services/',
        metaData: {
            title: 'Professional Towing Services in Salem | Salem Towing Services',
            description: 'Explore our extensive towing services in Salem. From emergency support to comprehensive vehicle transport, our dedicated team is available 24/7. Contact us for efficient towing solutions.',
        },
        h1: 'Towing Services in Salem, OR',
        h2: 'Your Trusted Partner for All Towing and Roadside Needs',
        content: <Box>
            <Stack spacing={5}>
                <Heading as="h2" size="xl">Salem Towing Service: Your Premier Roadside Assistance Provider in Salem,
                    OR</Heading>
                <Text>Salem Towing Service is honored to be your first choice for professional towing and roadside
                    assistance in Salem, OR. We excel in offering prompt, reliable, and safe services, catering to a
                    variety of needs, including vehicle breakdowns, emergency towing, or urgent roadside assistance. Our
                    unwavering commitment to excellence and customer satisfaction distinguishes us as a leading service
                    provider in the region.</Text>

                <Heading as="h3" size="lg">Why Salem Towing Service Stands Out</Heading>
                <List spacing={3}>
                    <ListItem><Text><b>Skilled Team:</b> Our experienced professionals are adept at managing diverse
                        towing scenarios, ensuring the safe and skillful handling of your vehicle.</Text></ListItem>
                    <ListItem><Text><b>Modern Towing Equipment:</b> Utilizing the latest in towing technology, we offer
                        superior service for all types of towing needs, from simple tows to complex
                        recoveries.</Text></ListItem>
                    <ListItem><Text><b>Always Available:</b> Our services are accessible 24/7, providing reliable
                        support day or night for any roadside emergency.</Text></ListItem>
                    <ListItem><Text><b>Fair and Transparent Pricing:</b> We believe in offering high-quality service at
                        competitive rates, ensuring transparency and fairness in pricing.</Text></ListItem>
                    <ListItem><Text><b>Expert Local Knowledge:</b> Our extensive knowledge of Salem, OR, and surrounding
                        areas means faster response times and efficient service delivery.</Text></ListItem>
                </List>

                <Heading as="h3" size="lg">Diverse Towing and Roadside Assistance Services</Heading>
                <List spacing={3}>
                    <ListItem><Text><b>Emergency Towing:</b> Equipped to handle all kinds of vehicles, we offer reliable
                        emergency towing, ensuring your safety throughout the process.</Text></ListItem>
                    <ListItem><Text><b>Roadside Assistance:</b> Stuck with a flat tire, dead battery, or out of fuel?
                        Our roadside assistance team is prepared to help you with minimal delay.</Text></ListItem>
                    <ListItem><Text><b>Flat Tire Services:</b> Facing a flat tire issue? Our team provides fast tire
                        change or repair services, ensuring your safe return to the road.</Text></ListItem>
                    <ListItem><Text><b>Car Lockout Services:</b> If you're locked out of your car, our efficient and
                        damage-free lockout services will regain access to your vehicle swiftly.</Text></ListItem>
                    <ListItem><Text><b>Long-Distance Towing:</b> For long-distance vehicle transportation, we offer
                        reliable towing services, ensuring safe delivery to your destination.</Text></ListItem>
                </List>

                <Heading as="h3" size="lg">Tailored Services for Individual and Commercial Needs</Heading>
                <Text>At Salem Towing Service, we understand that each client has unique requirements. We provide
                    customized towing and roadside assistance solutions for both personal and business purposes,
                    ensuring your specific needs are met with precision and care.</Text>

                <Heading as="h3" size="lg">Prioritizing Your Safety and Satisfaction</Heading>
                <Text>Your safety and contentment are our highest priorities. We are devoted to delivering exceptional
                    quality services that not only meet but exceed your expectations, ensuring a smooth and efficient
                    experience from start to finish.</Text>

                <Heading as="h3" size="lg">Contact Salem Towing Service</Heading>
                <Text>For exceptional towing and roadside assistance in Salem, OR, look to Salem Towing Service. Contact
                    us today for skilled and prompt service. We are here to support you at all times.</Text>
            </Stack>
        </Box>,
        services: [
            {
                id: '0',
                text: () => <Text><b><Link href={'/emergency-towing'}>24 Hour Emergency Towing</Link></b> Our fleet is
                    always ready, featuring well-maintained tow trucks and experienced drivers, ensuring swift arrival
                    and secure transportation. Whether you're near the Willamette River or in downtown Salem, we're
                    familiar with every corner of the area to quickly locate and assist you.</Text>
            },
            {
                id: '1',
                text: () => <Text><b><Link href={'/roadside-assistance'}>Roadside Assistance</Link></b> Need minor help
                    to get your vehicle moving again? Our proficient roadside service technicians are here to assist
                    with battery jump-starts, tire changes, or lockout services. Whatever your roadside challenge, Salem
                    Towing Service is ready to lend a hand.</Text>
            },
            {
                id: '2',
                text: () => <Text><b><Link href={'/vehicle-recovery'}>Vehicle Recovery</Link></b> Beyond towing, we also
                    specialize in vehicle recovery from all scenarios - ditches, accidents, or off-road incidents. Let
                    us know your vehicle's location and desired destination, and we will ensure its safe and efficient
                    recovery.</Text>
            },
            {
                id: '3',
                text: () => <Text><b><Link href={'/long-distance-towing'}>Long-Distance Towing</Link></b> For
                    long-distance moves or vehicle transportation, trust us for hassle-free long-distance towing. We're
                    equipped to handle your needs with the utmost care, ensuring your vehicle's safety over any
                    distance.</Text>
            },
            {
                id: '4',
                text: () => <Text><b><Link href={'/flatbed-towing'}>Flatbed Towing</Link></b> Need specialized care for
                    your vehicle during transport? Our flatbed towing service is ideal for luxury cars, classic
                    vehicles, or in accident recovery scenarios, offering the highest level of protection and
                    care.</Text>
            },
            {
                id: '5',
                text: () => <Text><b><Link href={'/car-lockout-service'}>Car Lockout Service</Link></b> Locked out of
                    your vehicle? Our expert team in Salem, OR, provides quick, non-invasive lockout services. We use
                    advanced techniques to unlock your car without any damage, ensuring you regain access efficiently
                    and securely.</Text>
            },
            {
                id: '6',
                text: () => <Text><b><Link href={'/fuel-delivery'}>Fuel Delivery Service</Link></b> Run out of gas in
                    Salem, OR? Our prompt fuel delivery service will bring you the fuel you need to continue your
                    journey. We're committed to helping you avoid the inconvenience of an empty tank, offering quick and
                    reliable service.</Text>
            },
            {
                id: '7',
                text: () => <Text><b><Link href={'/jump-start-service'}>Jump Start Service</Link></b> Dealing with a
                    dead battery? Our team in Salem, OR, provides fast and safe jump start services. We'll quickly get
                    your car running again, ensuring you can resume your travels with minimal disruption.</Text>
            },
            {
                id: '8',
                text: () => <Text><b><Link href={'/flat-tire-service'}>Flat Tire Service</Link></b> Encountered a flat
                    tire in Salem, OR? Our team is ready to assist with quick tire changes and repairs. We'll ensure
                    your safety by getting you back on the road promptly and efficiently.</Text>
            },
        ]
    },
    contact: {
        url: 'https://www.salemtowtruck.com/contact/',
        metaData: {
            title: 'Contact Us - Salem Towing Services | Reliable Towing in Salem, OR',
            description: 'Need towing or roadside assistance in Salem, OR? Contact Salem Towing Services for prompt and reliable service.'
        },
        h1: 'Contact Salem Towing Services',
        subtitle: 'We are here to assist you with all your towing needs in Salem. Reach out to us for fast, reliable, and professional towing services.',
        sectionHeading: 'Dependable Towing Just a Call Away',
        signUpDescription: 'Get in touch with us for any towing or roadside assistance needs. Our team is ready to provide you with quick and reliable solutions.',
        feedbackText: 'Your feedback is important to us! Let us know how we can improve our services or share your positive experiences with Salem Towing Services.',
        closingText: 'Contact us today for professional and efficient towing in Salem.',
        valueProps: [
            {
                title: 'Around the Clock Service:',
                description: 'Vehicle emergencies don’t wait for convenient times. At Salem Towing Service, we understand the need for immediate assistance. Our team of licensed professionals and our extensive network of tow trucks are available 24/7, ensuring rapid roadside assistance whenever you need it. Our commitment to quick response and fast towing, combined with our exceptional customer service, means you can count on us to be there for you, anytime.'
            },
            {
                title: 'Comprehensive Repair and Recovery Services:',
                description: 'Salem Towing Service goes beyond just towing; we offer a wide array of repair and recovery services. Our roadside assistance solutions are designed to handle everything from jump starts and tire changes to lockout services. With our expert technicians equipped with the necessary tools, we aim to resolve most automobile issues on the spot and get you back on your journey as quickly as possible.'
            },
            {
                title: 'State-of-the-Art Equipment:',
                description: 'Equipped with years of expertise and certification, our drivers at Salem Towing Service use only the best in towing technology. Our advanced towing equipment ensures that every job, big or small, is completed quickly and safely. Catering to a variety of vehicle types, including cars, motorcycles, SUVs, and trucks, we guarantee a service that meets your needs with precision and care.'
            },
            {
                title: 'Expert Long-Distance Towing:',
                description: 'Whether your destination is just around the corner or across state lines, Salem Towing Service provides professional and secure long-distance towing services. Our team specializes in safe and efficient vehicle transportation, ensuring your vehicle’s safety over any distance. With us, you can expect a reliable service that efficiently manages your long-distance towing needs while keeping costs affordable.'
            },
            {
                title: 'Our Promise to You:',
                description: 'At Salem Towing Service, our top priority is to ease our customers’ stress during difficult times. We are dedicated to exceeding your expectations, offering unparalleled customer service and a commitment to a seamless towing experience from start to finish. Your satisfaction and safety are at the heart of everything we do.'
            },
        ]
    },
    blog: {
        url: 'https://www.salemtowtruck.com/blog/',
        metaData: {
            title: 'Salem Towing Services Blog - Latest News and Tips on Towing',
            description: 'Stay up to date with the latest news, tips, and insights from Salem Towing Services. Our blog is your go-to resource for all towing-related information in Salem, OR.'
        },
        h1: 'Towing and Roadside Assistance Insights',
        h2: 'Our Blog',
        heading: 'Stay informed with the latest updates and helpful tips from Salem Towing Service. Whether it’s towing advice or industry news, our blog is a valuable resource for anyone on the road.',
        posts
    },
    emergencyTowing: {
        url: 'https://www.salemtowtruck.com/emergency-towing/',
        metaData: {
            title: '24/7 Emergency Towing in Salem, OR | Salem Towing Service',
            description: 'Immediate and reliable emergency towing services in Salem, OR. Available around the clock for any towing emergency.'
        },
        h1: '24/7 Emergency Towing Services in Salem, OR | Salem Towing Service',
        subtitle: 'Fast, Reliable, and Efficient Emergency Towing in Salem',
        body: <EmergencyTowing/>
    },
    roadsideAssistance: {
        url: 'https://www.salemtowtruck.com/roadside-assistance/',
        metaData: {
            title: 'Reliable Roadside Assistance in Salem, OR | Salem Towing Service',
            description: 'Comprehensive roadside assistance services in Salem, OR. From flat tires to battery jumps, we\'re here to help you get back on the road quickly.'
        },
        h1: 'Comprehensive Roadside Assistance in Salem, OR | Salem Towing Service',
        subtitle: 'Your Trusted Resource for Fast and Efficient Roadside Assistance',
        body: <RoadsideAssistance/>
    },
    flatbedTowing: {
        url: 'https://www.salemtowtruck.com/flatbed-towing/',
        metaData: {
            title: 'Secure Flatbed Towing in Salem, OR | Salem Towing Service',
            description: 'Specialized flatbed towing services in Salem, OR. Perfect for luxury vehicles, classic cars, and more, ensuring safe and secure transport.'
        },
        h1: 'Reliable Flatbed Towing Services in Salem, OR | Salem Towing Service',
        subtitle: 'Safe, Secure, and Specialized Flatbed Towing Solutions',
        body: <FlatbedTowing/>
    },
    carLockoutService: {
        url: 'https://www.salemtowtruck.com/car-lockout-service/',
        metaData: {
            title: 'Prompt Car Lockout Services in Salem, OR | Salem Towing Service',
            description: 'Professional car lockout services in Salem, OR. Fast, reliable assistance to regain access to your vehicle.'
        },
        h1: 'Professional Car Lockout Services in Salem, OR | Salem Towing Service',
        subtitle: 'Quick and Damage-Free Car Lockout Solutions',
        body: <CarLockoutService/>
    },
    vehicleRecovery: {
        url: 'https://www.salemtowtruck.com/vehicle-recovery/',
        metaData: {
            title: 'Efficient Vehicle Recovery in Salem, OR | Salem Towing Service',
            description: 'Expert vehicle recovery services in Salem, OR. Professional and safe recovery for your vehicle in challenging situations.'
        },
        h1: 'Professional Vehicle Recovery Services in Salem, OR | Salem Towing Service',
        subtitle: 'Safe and Efficient Vehicle Recovery Solutions',
        body: <VehicleRecovery/>
    },
    flatTire: {
        url: 'https://www.salemtowtruck.com/flat-tire-services/',
        metaData: {
            title: 'Reliable Flat Tire Services in Salem, OR | Salem Towing Service',
            description: 'Quick and dependable flat tire services in Salem, OR. Get back on the road swiftly with our expert tire repair and replacement solutions.'
        },
        h1: 'Expert Flat Tire Services in Salem, OR | Salem Towing Service',
        subtitle: 'Fast, Reliable Solutions for Flat Tire Emergencies',
        body: <FlatTire/>
    },
    longDistanceTowing: {
        url: 'https://www.salemtowtruck.com/long-distance-towing/',
        metaData: {
            title: 'Reliable Long-Distance Towing in Salem, OR | Salem Towing Service',
            description: 'Safe and secure long-distance towing services in Salem, OR. Trust us for your long-distance vehicle transportation needs.'
        },
        h1: 'Trusted Long-Distance Towing Services in Salem, OR | Salem Towing Service',
        subtitle: 'Secure and Dependable Long-Distance Towing',
        body: <LongDistanceTowing/>
    },
    fuelDelivery: {
        url: 'https://www.salemtowtruck.com/fuel-delivery/',
        metaData: {
            title: 'Fast Fuel Delivery in Salem, OR | Salem Towing Service',
            description: 'Ran out of gas? Depend on Salem Towing Service for quick and reliable fuel delivery in Salem, OR.'
        },
        h1: 'Emergency Fuel Delivery Services in Salem, OR | Salem Towing Service',
        subtitle: 'Prompt and Efficient Fuel Delivery When You Need It Most',
        body: <FuelDelivery/>
    },
    jumpStartService: {
        url: 'https://www.salemtowtruck.com/jump-start-service/',
        metaData: {
            title: 'Professional Jump Start Service in Salem, OR | Salem Towing Service',
            description: 'Need a jump start in Salem, OR? Our expert team is ready to revive your vehicle’s battery quickly and safely.'
        },
        h1: 'Dependable Jump Start Services in Salem, OR | Salem Towing Service',
        subtitle: 'Quick and Safe Battery Revival Solutions',
        body: <JumpStart/>
    },
    testimonials: {
        h1: 'Discover why our customers love us',
        h2: 'Testimonials',
        heading: 'For all your roadside needs, Salem Towing Service is here for you when you need us most',
        testimonials: [
            {
                avatarUrl: 'https://i.pravatar.cc/150?img=45',
                name: 'Linda G.',
                title: 'Salem, OR',
                quote: 'After getting stranded on a remote road, Salem Towing Service was quick to respond. Their team was professional and incredibly reassuring. A stressful situation became manageable. I can\'t thank them enough!'
            },
            {
                avatarUrl: 'https://i.pravatar.cc/150?img=68',
                name: 'John D.',
                title: 'Salem, OR',
                quote: 'Salem Towing Service was a lifesaver when I had a flat tire last week. They arrived quickly and got me back on the road in no time. Highly recommend their efficient and friendly service!'
            },
            {
                avatarUrl: 'https://i.pravatar.cc/150?img=32',
                name: 'Emily R.',
                title: 'Salem, OR',
                quote: 'I was impressed with the professionalism of Salem Towing Service when I needed emergency towing after an accident. They handled everything smoothly, making a stressful situation much easier.'
            },
        ]
    },
    '404': {
        metaData: {
            title: `404 | Page Not Found - Salem Towing Service | 24-Hour Towing | ${phoneNumberWithDashes}`,
            description: 'Oops! The page you\'re looking for could not be found. Salem Towing Service offers professional towing and roadside assistance. Contact us for reliable help.',
        },
        h1: 'Oops, looks like you hit a roadblock!',
        subtitle: 'Sorry, the page you are looking for does not exist.',
        p: 'Whether you need emergency towing, vehicle recovery, or any other roadside service, Salem Towing Service has got you covered 24/7.'
    }
}