import React from "react";
import {Box, Heading, Link, ListItem, Stack, Text, UnorderedList} from "@chakra-ui/react";
import {SiteData} from "../../Constants/siteData";

export const FlatbedTowing = () => {
    return (
        <Box>
            <Stack spacing={3} py={5}>
                <Heading as={'h3'}>Safe and Secure Flatbed Towing in Salem, OR</Heading>
                <Text>
                    Salem Towing Service specializes in flatbed towing, offering the safest and most secure way to
                    transport your vehicle in Salem, OR. Perfect for luxury vehicles, exotic cars, or any vehicle that
                    requires special care, our flatbed towing ensures your vehicle is transported without risk of damage
                    or wear.
                </Text>
                <Text>
                    Our professional team is trained to handle your vehicle with the utmost care, providing a worry-free
                    towing experience.
                </Text>
                <UnorderedList spacing={3}>
                    <ListItem>
                        <b>Advanced Equipment:</b> Our state-of-the-art flatbed tow trucks are equipped to transport
                        your vehicle safely.
                    </ListItem>
                    <ListItem>
                        <b>Damage-Free Transport:</b> Flatbed towing offers the best protection for your vehicle,
                        preventing wear and tear during transport.
                    </ListItem>
                    <ListItem>
                        <b>Specialized Care:</b> Ideal for luxury cars, classic vehicles, or cars with low ground
                        clearance, ensuring your vehicle’s safety is our priority.
                    </ListItem>
                    <ListItem>
                        <b>Versatile Solutions:</b> We provide flatbed towing services for a wide range of vehicles,
                        meeting all your towing needs.
                    </ListItem>
                    <ListItem>
                        <b>Professional Service:</b> Our experienced operators ensure the highest level of service and
                        attention to detail.
                    </ListItem>
                </UnorderedList>
                <Text>
                    Choose Salem Towing Service for secure flatbed towing in Salem, OR. We guarantee the safe
                    transportation of your vehicle.
                </Text>
                <Heading as={'h4'}>Contact Us for Flatbed Towing Services</Heading>
                <Text>
                    Need flatbed towing in Salem, OR? Contact us for professional and secure towing solutions. We’re
                    here to assist with all your flatbed towing needs.
                </Text>
                <Text>
                    <Link color="teal.500" href={SiteData.telLink}>
                        Call us at {SiteData.phoneNumber} to schedule your flatbed tow.
                    </Link>
                </Text>
                <Text>
                    Trust in Salem Towing Service for top-tier flatbed towing services and experience the safety and
                    care we provide.
                </Text>
            </Stack>
        </Box>
    )
}